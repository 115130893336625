import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import Media from 'components/media';
import ScrollingTitle from 'components/scrollingTitle';
import gsap from 'gsap';
import CharAnimation, { titleAnimation } from 'animations/charAnimation';
import LineAnimation, { textAnimation } from 'animations/lineAnimation';
import { Draggable, InertiaPlugin } from 'gsap/all';
import { slideDuration } from 'constants/animation';
import PropTypes from 'prop-types';

const SlidesContainer = styled.div`
    width: 100%;
    padding: 7vw 0 20vw 0;

    .slideLeft,
    .slideRight {
      cursor: pointer;
      position: absolute;
      z-index: 10;

      top: 0;
      height: calc(35vw + 1px);
      background-color: rgba(1, 1, 1, 0.5);

      border: none;
      padding: 0;
    }

    .slideLeft {
      left: 0;
      width: calc(100% / 3);
    }

    .slideRight {
      right: 0;
      width: calc(100% / 6);
    }

    .titleContainer {
      margin-left: calc(100% * 2 / 6);
      margin-bottom: 3.5vw;
      width: calc(100% / 6);
    }

    .slidesOuter {
      position: relative;
      width: 100%;
    }

    .slidesInner {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }

    .upper {
      
      z-index: 20;
      pointer-events: none;
      li {
        transform: translateX(calc(100% * 2 / 3));
      }
    }

    .slide {
      width: calc(100% / 2);
      height: 100%;
      min-height: 50vw;
      position: absolute;
      top: 0;
      left: 0;
    }

    .slideContent {
      position: relative;
    }

    .gatsby-image-wrapper div {
      padding-bottom: 70% !important;
      pointer-events: none;
      height: 35vw; !important
    }

    .title {
      position: absolute;
      left: 0;
      transform: rotate(-90deg) translateY(-45vw);
      transform-origin: top right;
      text-align: center;
      width: 35vw;
      pointer-events: none;

      span .char {
        pointer-events: none;
      }
    }

    .date {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
      transform: translateY(calc(-100% * 2 / 3));
      width: calc(100% / 3);
    }

    .textContainer {
      padding-top: 38.5vw;
      display: flex;
      justify-content: flex-end;

      .bodyText,
      .headline {
        width: calc(100% / 3);
      }

      h4 {
        font-size: 1.3rem;
      }
    }

    @media (max-width: 950px) {

      padding-bottom: 25vw;

      .slideLeft, .slideRight {
        height: calc(50vw + 1px);
      }

      .gatsby-image-wrapper div {
          padding-bottom: 100% !important;
          height: 50vw; !important
      }

      .upper {
        li {
          width: calc(100% * 2 / 3);
          transform: translateX(50%);
        }
      }

      .title {
        transform: rotate(-90deg) translateY(-69vw);
        width: 50vw;
      }

      .textContainer {
        padding-top: 52.5vw;
    
        .bodyText,
        .headline {
          width: 50%;
        }
      }
    }

    @media (max-width: 650px) {

      padding-bottom: 50vw;

      .lower .slide {
        width: calc(100% * 2 / 3);
      }

      .slideLeft, .slideRight {
        width: calc(100% / 6);
        height: calc(66.5vw + 1px);
      }

      .textContainer {
        padding-top: calc(66.5vw + 2.5vw);
      }

      .title {
        transform: rotate(-90deg) translateY(-94vw);
        width: 66.5vw;
      }

      .date {
        transform: translate(-75%, calc(-100% * 2 / 3));

        .lineContainer {
          width: 125%;
        }
      }
    }

    @media (max-width: 550px) {

        .titleContainer {
        margin-left: 0;
        margin-bottom: 7.5vw;
     
        width: calc(100% / 3);
      }

      .textContainer {
        flex-direction: column;
        transform: translateX(-25%);

        .bodyText,
        .headline {
          width: 100%;

          h4, p {
            padding-left: 0;
            padding-right: 0;
          }
        }

        .headline {
          padding-top: 10px;
          padding-bottom: 10px;
        }
      }


    }
  `;

const Slideshow = ({ data }) => {
  let numSlides = useRef(data.length);
  let slidesOuterRef = useRef(null);
  let slidesInner = useRef(null);
  let slideRefsLower = [];
  let slideRefsUpper = [];

  let titleRefs = [];
  let headlineRefs = [];
  let bodyRefs = [];
  let dateRefs = [];

  for (let i = 0; i < numSlides.current; i++) {
    slideRefsLower.push(useRef(null));
    slideRefsUpper.push(useRef(null));
    titleRefs.push(useRef(null));
    headlineRefs.push(useRef(null));
    bodyRefs.push(useRef(null));
    dateRefs.push(useRef(null));
  }

  const wrapPartial = (min, max) => {
    var r = max - min;

    return function(value) {
      var v = value - min;

      return ((r + (v % r)) % r) + min;
    };
  };

  const wrap = wrapPartial(-200, (numSlides.current - 2) * 100);

  const setPosition = below => {
    for (let i = 0; i < numSlides.current; i++) {
      gsap.set(slideRefsLower[i], {
        xPercent: below ? i * 100 + 25 : i * 100 + (100 * 2) / 3,
        modifiers: {
          xPercent: wrap,
        },
      });
    }
  };

  const handleSwitchPosition = mediaQuery => {
    if (mediaQuery.matches) {
      setPosition(true);
      updateAnimation();
    } else {
      setPosition(false);
      updateAnimation();
    }
  };

  const setCurrentImageWithDirection = direction => {
    if (currentLocation.current === numSlides.current - 1 && direction === 1) {
      currentLocation.current = 0;
    } else if (currentLocation.current === 0 && direction === -1) {
      currentLocation.current = numSlides.current - 1;
    } else {
      currentLocation.current += direction;
    }
  };

  const animateContent = direction => {
    titleAnimation(currentLocation.current, titleRefs, direction);
    textAnimation(currentLocation.current, headlineRefs, direction);
    textAnimation(currentLocation.current, bodyRefs, direction);
    textAnimation(currentLocation.current, dateRefs, direction);
  };

  const handleSlide = direction => {
    // Right == 1, Left  == -1

    setCurrentImageWithDirection(direction);

    animateSlides(-direction);
    animateContent(direction);
  };

  // LEFT SLIDESHOW / DRAGGABLE

  let dragInstance = useRef(null);
  let proxyRef = useRef();

  let transform = useRef(null);

  let animation = useRef(null);
  let slideWidth;
  let wrapWidth;
  let slideAnimation = gsap.to({}, 0.1, {});

  const animateSlides = direction => {
    slideAnimation.kill();

    const xVal = parseFloat(
      transform.current.x.substring(0, transform.current.x.length - 2)
    );

    const x = snapX(xVal + direction * slideWidth);

    slideAnimation = gsap.to(proxyRef.current, slideDuration, {
      x: x,
      onUpdate: updateProgress,
    });
  };

  let currentLocation = useRef(0);
  let prevDestination = useRef(0);

  const updateContent = destination => {
    let upcomingImage;

    if (destination % numSlides.current === 0) {
      upcomingImage = 0;
    } else if (destination <= 0) {
      upcomingImage = (destination % numSlides.current) * -1;
    } else if (destination > 0) {
      upcomingImage =
        ((destination % numSlides.current) - numSlides.current) * -1;
    }

    currentLocation.current = upcomingImage;

    let newDirection;

    if (prevDestination.current === destination) {
      newDirection = 0;
    } else if (destination < prevDestination.current) {
      newDirection = 1;
    } else if (destination > prevDestination.current) {
      newDirection = -1;
    }

    prevDestination.current = destination;

    if (newDirection !== 0) {
      animateContent(newDirection);
    }
  };

  const snapX = x => {
    // console.log(Math.round(x / slideWidth));
    updateContent(Math.round(x / slideWidth));

    return Math.round(x / slideWidth) * slideWidth;
  };

  const updateProgress = () => {
    const xVal = parseFloat(
      transform.current.x.substring(0, transform.current.x.length - 2)
    );

    const xPercentage = xVal / wrapWidth;

    const trans =
      xPercentage < 0 ? Math.ceil(xPercentage * -1) + xPercentage : xPercentage;

    animation.current.progress(trans);
  };

  const setHeight = () => {
    const slideContent = document.getElementById('slideContentImage');

    const height = slideContent.getBoundingClientRect().height;

    gsap.set(slidesOuterRef, { height: height });
  };

  const resize = () => {
    setHeight();

    const xVal = parseFloat(
      transform.current.x.substring(0, transform.current.x.length - 2)
    );

    const norm = xVal / wrapWidth || 0;

    slideWidth = slideRefsLower[0].getBoundingClientRect().width;

    wrapWidth = slideWidth * numSlides.current;

    gsap.set(proxyRef.current, {
      x: norm * wrapWidth,
    });

    animateSlides(0);
    slideAnimation.progress(1);
  };

  const updateAnimation = () => {
    animation.current = gsap.to(slideRefsLower, 0.8, {
      xPercent: '+=' + numSlides.current * 100,
      ease: 'none',
      paused: true,
      repeat: -1,
      modifiers: {
        xPercent: wrap,
      },
    });
  };

  useEffect(() => {
    setHeight();

    proxyRef.current = document.createElement('div');
    gsap.set(proxyRef.current, {
      x: '+=0',
    });
    transform.current = proxyRef.current._gsap;

    gsap.registerPlugin(Draggable, InertiaPlugin);

    // SET POSITION

    const switchPosition = window.matchMedia('(max-width: 650px)');
    handleSwitchPosition(switchPosition);
    switchPosition.addListener(handleSwitchPosition);

    dragInstance.current = Draggable.create(proxyRef.current, {
      trigger: slidesInner,
      type: 'x',
      // edgeResistance: 0.65,
      inertia: true,
      onPress: function() {
        slideAnimation.kill();
        this.update();
      },
      onDrag: updateProgress,
      onThrowUpdate: updateProgress,
      snap: {
        x: snapX,
      },
    });

    animation.current = gsap.to(slideRefsLower, 0.8, {
      xPercent: '+=' + numSlides.current * 100,
      ease: 'none',
      paused: true,
      repeat: -1,
      modifiers: {
        xPercent: wrap,
      },
    });

    resize();

    titleAnimation(currentLocation.current, titleRefs, -1);
    textAnimation(currentLocation.current, headlineRefs, -1);
    textAnimation(currentLocation.current, bodyRefs, -1);
    textAnimation(currentLocation.current, dateRefs, -1);

    window.addEventListener('resize', resize);

    return () => {
      window.removeEventListener('resize', resize);
      switchPosition.removeListener(handleSwitchPosition);
    };
  }, []);

  return (
    <SlidesContainer>
      <ScrollingTitle title={'Timeline'} width={6} />
      <div
        className="slidesOuter"
        ref={div => {
          slidesOuterRef = div;
        }}
      >
        <ul
          className="slidesInner lower"
          ref={ul => {
            slidesInner = ul;
          }}
        >
          {data.map(({ node }, i) => {
            return (
              <li
                key={node.id}
                className="slide"
                ref={li => {
                  slideRefsLower[i] = li;
                }}
              >
                <div className="slideContent" id="slideContentImage">
                  <Media
                    videoCheck={node.media.file.url.slice(-3)}
                    videoSrcURL={node.media.file.url}
                    fluid={node.media.fluid}
                    alt={node.media.title}
                    title={node.media.title}
                    description={node.media.description}
                  />
                </div>
              </li>
            );
          })}
        </ul>
        <ul className="slidesInner upper">
          {data.map(({ node }, i) => {
            return (
              <li
                key={node.id}
                className="slide slideText"
                ref={li => {
                  slideRefsUpper[i] = li;
                }}
              >
                <div className="slideContent slideContentText">
                  <div className="date pointerAuto">
                    <LineAnimation
                      text={node.date}
                      ele={i}
                      textRefs={dateRefs}
                    />
                  </div>
                  <h3 className="title pointerAuto">
                    <CharAnimation
                      text={node.title}
                      titleRefs={titleRefs}
                      ele={i}
                    />
                  </h3>
                  <div className="textContainer">
                    <div className="headline">
                      <LineAnimation
                        html={`<h4>${node.headline}</h4>`}
                        ele={i}
                        textRefs={headlineRefs}
                      />
                    </div>

                    <div className="bodyText">
                      <LineAnimation
                        html={node.body.childMarkdownRemark.html}
                        ele={i}
                        textRefs={bodyRefs}
                      />
                    </div>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
        <button
          className="slideLeft"
          onClick={() => {
            handleSlide(-1);
          }}
        ></button>
        <button
          className="slideRight"
          onClick={() => {
            handleSlide(1);
          }}
        ></button>
      </div>
    </SlidesContainer>
  );
};

Slideshow.propTypes = {
  data: PropTypes.array.isRequired,
};

export default Slideshow;
