import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import ScrollingTitle from 'components/scrollingTitle';
import Media from 'components/media';
import { Link } from 'gatsby';
import gsap from 'gsap';
import { useInView } from 'react-intersection-observer';
import PropTypes from 'prop-types';

const FilmContainer = styled.div`
  margin: 0 auto;
  position: relative;

  .filmContent {
    position: relative;
    padding: 21vw 0 4vw 0;

    .filmListContainer {
      position: relative;
    }
  }

  .titleContainer {
    width: calc(100% / 6);
    margin-left: calc(100% * 1 / 6);
  }

  @media (max-width: 800px) {
    .filmContent {
      padding-bottom: 12vw;
    }
  }

  @media (max-width: 550px) {
    .titleContainer {
      width: calc(100% / 3);
      margin-left: calc(100% * 2 / 3);
    }

    .filmContent {
      padding-bottom: 30vw;
    }
  }
`;

const FilmLinks = styled.ul`
  display: flex;
  flex-direction: column;

  li {
    text-align: left;
    font-size: 1.8vw;

    .year {
      display: inline-block;
      font-size: calc(1.8vw * 0.275);
      transform: translateY(calc(-1.8vw * 0.6));
    }

    a {
      width: 100%;
      display: block;
    }

    .linkContainer {
      width: 100%;
      overflow-x: hidden;
      padding: 8px 0 8px 10px;
      margin-left: calc(100% / 3);
      opacity: 0.5;
      transform-origin: center left;
      pointer-events: none;
    }

    .link {
      transform: translateX(-100%);
      width: calc(100% / 3);
      min-width: 280px;
      transform-origin: center left;
      pointer-events: none;
    }
  }

  li:first-child a {
    padding-top: 0;
  }

  li:last-child a {
    padding-bottom: 0;
  }

  @media (max-width: 950px) {
    li {
      font-size: 2.5vw;

      .year {
        font-size: calc(2.5vw * 0.275);
        transform: translateY(calc(-2.5vw * 0.6));
      }
    }
  }

  @media (max-width: 800px) {
    li {
      font-size: 1.3rem;

      .year {
        font-size: calc(1.3rem * 0.275);
        transform: translateY(calc(-1.3rem * 0.6));
      }

      .linkContainer {
        margin-left: 25%;
        padding-left: 0;
      }
    
  }
`;

const ImageContainer = styled.ul`
  width: calc(100% * 2 / 3);
  height: calc(75vw / 2);
  overflow: hidden;
  position: absolute;
  top: calc(3.5vw + 42px);
  left: calc(100% * 1 / 6);
  pointer-events: none;
  z-index: -1;
  transition: transform 400ms ease-out;

  li {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;

    .gatsby-image-wrapper {
      position: absolute;
      min-width: 100%;
      min-height: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0.6;
    }
  }

  .imageDescription {
    width: 25%;
    position: absolute;
    bottom: 0;
    right: 0;

    p {
      margin-bottom: 15px;
      font-size: 0.9rem;
    }
  }

  @media (max-width: 800px) {
    left: 0;
    width: 100%;
    height: 50vw;
  }

  @media (max-width: 550px) {
    top: calc(7.5vw + 42px);

    height: 60vw;

    .imageDescription {
      display: none;
    }

    li .gatsby-image-wrapper {
      width: 140%;
    }
  }
`;

const FilmList = ({ data }) => {
  let linkContainerRefs = [];
  let linkRefs = [];
  let selectedImage = useRef(0);
  let imageRefs = [];
  let fullPosts = [];
  let yearRefs = [];

  data.forEach(film => {
    if (film.node?.hero) {
      fullPosts.push(film);
      imageRefs.push(useRef(null));
      linkContainerRefs.push(useRef(null));
      linkRefs.push(useRef(null));
    }
  });

  const [refView, inView] = useInView({
    threshold: 0.3,
    triggerOnce: true,
  });

  useEffect(() => {
    gsap.set(imageRefs[selectedImage.current], { opacity: 1 });

    gsap.set(linkContainerRefs[selectedImage.current], {
      x: '1vw',
      opacity: 1,
    });

    gsap.set(linkRefs[selectedImage.current], { scale: 1.2 });

    if (inView) {
      const tl = gsap.timeline();
      tl.staggerTo(linkRefs, 0.7, { x: 0 }, 0.08);
    }

    return () => {};
  }, [inView]);

  let setTimeoutConst;
  let delay = 90;

  const handleEnter = node => {
    if (selectedImage.current !== node) {
      setTimeoutConst = setTimeout(() => {
        gsap.killTweensOf(imageRefs[node]);
        gsap.killTweensOf(linkContainerRefs[node]);

        gsap.to(imageRefs[selectedImage.current], 0.4, { autoAlpha: 0 });
        gsap.to(linkContainerRefs[selectedImage.current], 0.4, {
          x: '0',
          autoAlpha: 0.5,
        });

        gsap.to(linkRefs[selectedImage.current], 0.4, { scale: 1 });

        gsap.to(imageRefs[node], 0.4, {
          autoAlpha: 1,
        });
        gsap.to(linkContainerRefs[node], 0.4, {
          x: '1vw',
          autoAlpha: 1,
        });

        gsap.to(linkRefs[node], 0.4, { scale: 1.2 });
        selectedImage.current = node;
      }, delay);
    }
  };

  const handleLeave = () => {
    clearTimeout(setTimeoutConst);
  };

  return (
    <FilmContainer id="filmContainer" ref={refView}>
      <ScrollingTitle title={'All Films'} width={6} />

      <ImageContainer className="imageContainer">
        {fullPosts.map(({ node }, i) => {
          return (
            <li
              key={i}
              id={`image${i}`}
              ref={li => {
                imageRefs[i] = li;
              }}
            >
              <Media
                videoCheck={node.hero.file.url.slice(-3)}
                videoSrcURL={node.hero.file.url}
                fluid={node.hero.fluid}
                alt={node.hero.title}
                title={node.hero.title}
                description={node.hero.description}
              />
              <div
                className="imageDescription"
                dangerouslySetInnerHTML={{
                  __html: node.headline.childMarkdownRemark.html,
                }}
              />
            </li>
          );
        })}
      </ImageContainer>
      <div className="filmContent">
        <div className="filmListContainer">
          <FilmLinks
            id="filmList"
            data-scroll
            data-scroll-direction="vertical"
            data-scroll-speed="3"
            data-scroll-target="#filmContainer"
          >
            {fullPosts.map(({ node }, i) => {
              return (
                <li
                  key={node.id}
                  onMouseEnter={() => handleEnter(i)}
                  onMouseLeave={() => handleLeave()}
                >
                  <Link to={`/${node.slug}/`}>
                    <div
                      className="linkContainer"
                      ref={div => (linkContainerRefs[i] = div)}
                    >
                      <div className="link" ref={div => (linkRefs[i] = div)}>
                        {node.title}
                        <span
                          className="year"
                          ref={span => (yearRefs[i] = span)}
                        >
                          {node.year}
                        </span>
                      </div>
                    </div>
                  </Link>
                </li>
              );
            })}
          </FilmLinks>
        </div>
      </div>
    </FilmContainer>
  );
};

FilmList.propTypes = {
  data: PropTypes.array.isRequired,
};

export default FilmList;
