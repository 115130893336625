import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import gsap from 'gsap';
import SplitText from 'gsap/SplitText';
import PropTypes from 'prop-types';

const TextContainer = styled.div`
  .line {
    transform: translateX(-120%);
    display: block;
  }

  .lineContainer {
    position: relative;
    display: inline-block;
    width: 100%;
    overflow: hidden;
  }
`;

const LineAnimation = ({ html, text, textRefs, ele }) => {
  let refContainer = useRef(null);

  const wrap = (el, wrapper) => {
    el.parentNode.insertBefore(wrapper, el);
    wrapper.appendChild(el);
    wrapper.setAttribute('class', 'lineContainer');
  };

  useEffect(() => {
    const myElement = new SplitText(refContainer, {
      type: 'lines',
      linesClass: 'line line++',
    });

    setTextRefs(textRefs, ele, myElement.lines);

    for (const line of textRefs[ele]) {
      wrap(line, document.createElement('span'));
    }
  });

  return (
    <>
      {html ? (
        <TextContainer
          ref={div => (refContainer = div)}
          className={'textAnimationContainer'}
          dangerouslySetInnerHTML={{
            __html: html,
          }}
        />
      ) : (
        <TextContainer
          ref={div => (refContainer = div)}
          className={'textAnimationContainer'}
        >
          {text}
        </TextContainer>
      )}
    </>
  );
};

export const slideIn = (refs, direction) => {
  const tl = gsap.timeline();

  if (direction === -1) {
    tl.set(refs, { autoAlpha: 1 }).fromTo(
      refs,
      0.4,
      { x: '-120%' },
      {
        x: '0',
      }
    );
  }

  if (direction === 1) {
    tl.set(refs, { autoAlpha: 1 }).fromTo(
      refs,
      0.4,
      { x: '120%' },
      {
        x: '0',
      }
    );
  }
};

export const slideOut = (refs, direction) => {
  const tl = gsap.timeline();

  if (direction === -1) {
    tl.fromTo(
      refs,
      0.4,
      { x: '0' },
      {
        x: '120%',
      }
    ).set(refs, { autoAlpha: 1 });
  }

  if (direction === 1) {
    tl.fromTo(
      refs,
      0.4,
      { x: '0' },
      {
        x: '-120%',
      }
    ).set(refs, { autoAlpha: 1 });
  }
};

export const textAnimation = (position, textRefs, direction) => {
  for (let i = 0; i < textRefs.length; i++) {
    if (
      textRefs[i][0].style.transform !== 'translate(120%, 0px)' &&
      textRefs[i][0].style.transform !== 'translate(-120%, 0px)' &&
      textRefs[i][0].style.transform !== 'translate(-120%)' &&
      textRefs[i][0].style.transform !== 'translate(120%)'
    ) {
      slideOut(textRefs[i], direction);
    }
  }

  setTimeout(() => {
    slideIn(textRefs[position], direction);
  }, 400);
};

export const setTextRefs = (textRefs, ele, arr) => {
  textRefs[ele] = arr;
};

LineAnimation.propTypes = {
  html: PropTypes.string,
  text: PropTypes.string,
  textRefs: PropTypes.array.isRequired,
  ele: PropTypes.number.isRequired,
};

export default LineAnimation;
