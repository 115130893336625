import React, { Component } from 'react';
import styled from 'styled-components';
import gsap from 'gsap';
import PropTypes from 'prop-types';

const HeroTimerContainer = styled.div`
  width: 15vw;
  display: flex;
  align-items: center;
  height: 44px;
  justify-content: space-between;
  pointer-events: none;
  opacity: 0;

  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 50;

  .currentSlide,
  .totalSlides {
    font-size: 0.8rem;
    width: 15%;
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .barContainer {
    width: 50%;
    position: relative;
  }

  .progressBar,
  .fullBar {
    height: 1px;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
  }

  .fullBar {
    background-color: rgba(255, 255, 255, 0.4);
  }

  .progressBar {
    background-color: rgba(255, 255, 255, 1);
    transform: scaleX(0);
    z-index: 10;
  }

  @media (max-width: 950px) {
    visibility: hidden;
  }
`;

class HeroTimer extends Component {
  constructor(props) {
    super(props);
    this.progressBarRef = null;
    this.progressBarAnimation = null;
    this.timerRef = React.createRef();
    this.state = {
      timerSlide: 1,
    };
  }

  componentDidMount() {
    this.progressBarAnimation = gsap.to(
      this.progressBarRef,
      this.props.slideDelay,
      {
        paused: true,
        scaleX: 1,
        ease: 'none',
      }
    );
  }

  startBarAnimation = () => {
    this.progressBarAnimation.play();
  };

  updateTimerSlide = slide => {
    this.setState(
      {
        timerSlide: slide,
      },
      () => {
        // console.log(slide, this.state.timerSlide);
        this.progressBarAnimation.restart();
      }
    );
  };

  render() {
    return (
      <HeroTimerContainer
        ref={div => (this.timerRef = div)}
        id="heroTimerContainer"
      >
        <div className="currentSlide">0{this.state.timerSlide}</div>
        <div className="barContainer">
          <div
            className="progressBar"
            ref={div => {
              this.progressBarRef = div;
            }}
          ></div>
          <div className="fullBar"></div>
        </div>
        <div className="totalSlides">0{this.props.totalSlides}</div>
      </HeroTimerContainer>
    );
  }
}

HeroTimer.propTypes = {
  totalSlides: PropTypes.number,
  slideDelay: PropTypes.number,
};

export default HeroTimer;
