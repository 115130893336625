import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
import Gallery from 'components/gallery';
import Footer from 'components/footer';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Introduction from 'components/introduction';
import FilmList from 'components/filmList';
import Slideshow from 'components/slideshow';
import Contact from 'components/contact';
import Cursor from 'components/cursor';
import gsap from 'gsap';

const Main = styled.main`
  section {
    position: relative;
  }

  h1 {
    color: white;
    margin: 0 !important;
    font-size: 7vw !important;
  }

  .titleText {
    opacity: 0;
    width: 80%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;

    span {
      color: red;
    }
  }
`;

const Index = ({ data, location }) => {
  let segments = useRef(6);

  let scroll;

  const handleScroll = (destination, offset, time) => {
    scroll.scrollTo(destination, offset, time);
  };

  const setHeight = () => {
    gsap.set('#galleryContainer, .heroSlide', {
      height: `${window.innerHeight}px`,
    });
  };

  useEffect(() => {
    import('locomotive-scroll').then(locomotiveModule => {
      scroll = new locomotiveModule.default(
        {
          el: document.querySelector('[data-scroll-container]'),
          smooth: true,
          getSpeed: true,
          getDirection: true,
          useKeyboard: true,
          inertia: 0.7,
          smoothMobile: true,
          touchMultiplier: 2.5,
        },
        []
      );

      if (
        location?.state?.scrollTo &&
        location?.state?.scrollTo.slice(0, 1) === '#'
      ) {
        scroll.scrollTo(location.state.scrollTo, -100, 1000);
        location.state.scrollTo = null;
      }

      setHeight();

      setTimeout(() => {
        scroll.update();
      }, 300);

      window.addEventListener('resize', setHeight);
      window.addEventListener('resize', scroll.update());
    });

    return () => {
      window.removeEventListener('resize', setHeight);
      window.removeEventListener('resize', scroll.update());
      scroll.destroy();
    };
  }, []);

  return (
    <Layout handleScroll={handleScroll} location={location}>
      <Cursor />
      <div data-scroll-container>
        <Main>
          <section data-scroll-section>
            <Gallery data={data} segments={segments} />
          </section>

          <section data-scroll-section id="about">
            <Introduction data={data.contentfulIntroduction} />
          </section>
          <section data-scroll-section id="films">
            <FilmList data={data.allContentfulPost.edges} />
          </section>
          {/* <section data-scroll-section id="timeline">
            <Slideshow data={data.allContentfulTimeline.edges} />
          </section> */}
          <section data-scroll-section id="contact">
            <Contact data={data.contentfulContact} />
          </section>
        </Main>
        <Footer data={data.contentfulMotto} />
      </div>
    </Layout>
  );
};

Index.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default Index;

export const query = graphql`
  query HomepageQuery {
    allContentfulPost(
      filter: { node_locale: { eq: "en-US" } }
      sort: { order: DESC, fields: publishDate }
    ) {
      edges {
        node {
          id
          slug
          title
          year
          metaDescription {
            internal {
              content
            }
          }
          publishDate(formatString: "MMMM DD, YYYY")
          quote {
            childMarkdownRemark {
              html
            }
          }
          quoteLink
          quoteSource {
            childMarkdownRemark {
              html
            }
          }
          hero {
            fluid(maxWidth: 1000) {
              ...GatsbyContentfulFluid_withWebp
            }
            title
            description
            file {
              url
            }
          }
          headline {
            childMarkdownRemark {
              html
            }
          }
          galleryMedia {
            fluid(maxWidth: 1000) {
              ...GatsbyContentfulFluid_withWebp
            }
            title
            description
            file {
              url
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
          crew {
            childMarkdownRemark {
              html
            }
          }
          featuredMedia {
            fluid(maxWidth: 2200) {
              ...GatsbyContentfulFluid_withWebp
            }
            id
            title
            description
            file {
              url
            }
          }
          tag {
            id
            slug
            title
          }
        }
        next {
          slug
        }
        previous {
          slug
        }
      }
    }

    contentfulIntroduction {
      expertise3
      expertise2
      expertise1
      headline {
        childMarkdownRemark {
          html
        }
      }
      paragraph {
        childMarkdownRemark {
          html
        }
      }
      title
      media {
        fluid(maxWidth: 1800) {
          ...GatsbyContentfulFluid_withWebp
        }
        file {
          url
        }
      }
    }

    allContentfulTag {
      edges {
        node {
          slug
          title
        }
      }
    }

    allContentfulTimeline(
      filter: { node_locale: { eq: "en-US" } }
      sort: { order: DESC, fields: date }
    ) {
      edges {
        node {
          body {
            childMarkdownRemark {
              html
            }
          }
          id
          headline
          date
          media {
            fluid(maxWidth: 1000) {
              ...GatsbyContentfulFluid_withWebp
            }
            file {
              url
            }
            title
          }
          title
        }
      }
    }

    contentfulContact {
      title1
      title2
      email
      address {
        childMarkdownRemark {
          html
        }
      }
      media {
        fluid(maxWidth: 1000) {
          ...GatsbyContentfulFluid_withWebp
        }
        file {
          url
        }
        title
      }
    }

    contentfulMotto {
      motto {
        childMarkdownRemark {
          html
        }
        motto
      }
    }

    homeJson {
      title
      content {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
      gallery {
        title
        copy
        image {
          childImageSharp {
            fluid(maxHeight: 500, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
