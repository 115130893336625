import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import gsap from 'gsap';
import CharAnimation, {
  titleAnimation,
  titleAnimationInitial,
} from 'animations/charAnimation';
import HeroSlideshow from 'components/heroSlideshow';
import { slideDelay, slideDuration } from 'constants/animation';
import { Link } from 'gatsby';
import HeroLottie from 'components/heroLottie';

const GalleryContainer = styled.div`
  position: relative;
  height: 100vh;

  .gatsby-image-wrapper {
    height: 100%;
  }

  .horizontalLine {
    position: fixed;
    z-index: 30;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 1px;
    width: 100%;
    transform: scaleX(0);
    transform-origin: left center;
    background-color: rgba(255, 255, 255, 0.2);
    pointer-events: none;
  }
`;

const TitleContainer = styled.ul`
  color: white;
  font-size: 2.5rem;

  li {
    text-align: left;
    position: absolute;
    bottom: 2.8rem;
    right: 2rem;
    z-index: 30;
    transform: translateY(5px);
    pointer-events: none;

    .year {
      font-family: sans-serif;
      display: inline-block;
      font-size: calc(2.5rem * 0.275);
      transform: translateY(calc(-60% - 2.5rem * 0.6));
      font-weight: normal;
    }
  }

  @media (max-width: 700px) {
    li {
      right: 0.75rem;
      bottom: 1.8rem;
    }
  }

  @media (max-width: 500px) {
    font-size: 2rem;
  }
`;

const Gallery = ({ data, segments }) => {
  const allPosts = data.allContentfulPost.edges;

  let images = [];
  let titles = [];
  let featuredPosts = [];

  allPosts.forEach(post => {
    if (post?.node?.tag) {
      images.push(post.node.featuredMedia);
      titles.push(post.node.title);
      featuredPosts.push(post);
    }
  });

  let logoRefs = [];
  let blindRefs = [];
  let imageRefs = [];
  let titleRefs = [];
  let slideContentRefs = [];
  let heroTimerRef = useRef(null);
  let slidesOuterRef = [];

  slidesOuterRef.push(useRef(null));

  for (let i = 0; i < segments.current / 2; i++) {
    blindRefs.push(useRef(null));
  }

  for (let j = 0; j < images.length; j++) {
    imageRefs.push(useRef(null));
    logoRefs.push(useRef(null));
    titleRefs.push(useRef(null));
    slideContentRefs.push(useRef(null));
  }

  let currentImage = useRef(0);

  let timer = useRef(gsap.delayedCall(0, () => {}));
  let autoPlay = useRef(null);
  let animateImages = useRef(null);

  const setAutoPlay = fn => {
    autoPlay.current = fn;
  };

  const setAnimateImages = fn => {
    animateImages.current = fn;
  };

  const setTimer = (slideDelay, autoPlay) => {
    timer.current = gsap.delayedCall(slideDelay, autoPlay);
  };

  const setCurrentImageWithDirection = direction => {
    if (currentImage.current === images.length - 1 && direction === 1) {
      currentImage.current = 0;
    } else if (currentImage.current === 0 && direction === -1) {
      currentImage.current = images.length - 1;
    } else {
      currentImage.current += direction;
    }
  };

  const setCurrentImageWithDestination = destination => {
    currentImage.current = destination;
  };

  useEffect(() => {
    return () => {
      timer.current.kill();
    };
  }, []);

  const triggerHeroAnimations = () => {
    setTimer(slideDelay, autoPlay.current);
    heroTimerRef.current.startBarAnimation();
    gsap.to(slidesOuterRef[0], 0.1, { autoAlpha: 1 });
    gsap.to(slideContentRefs[0], slideDuration, { y: 0 });
    titleAnimationInitial(0, titleRefs, -1);

    gsap.to(
      '.hamburgerMenuContainer, .logoContainer, .icons, .triangleContainer',
      1,
      {
        autoAlpha: 1,
      }
    );

    gsap.to('#heroTimerContainer', 1, {
      opacity: 1,
    });

    const tl = gsap.timeline();
    animateImages.current(tl, 0);
  };

  return (
    <GalleryContainer id="galleryContainer">
      <TitleContainer>
        {featuredPosts.map((post, i) => {
          if (post?.node?.tag) {
            return (
              <li key={i}>
                <Link to={`/${post.node.slug}/`}>
                  <CharAnimation
                    text={`${post.node.title}`}
                    titleRefs={titleRefs}
                    ele={i}
                    year={post.node.year}
                  />
                </Link>
              </li>
            );
          }
        })}
      </TitleContainer>

      <HeroSlideshow
        images={images}
        imageRefs={imageRefs}
        titleAnimation={titleAnimation}
        titleRefs={titleRefs}
        blindRefs={blindRefs}
        setCurrentImageWithDirection={setCurrentImageWithDirection}
        setCurrentImageWithDestination={setCurrentImageWithDestination}
        currentImage={currentImage}
        timer={timer}
        setAutoPlay={setAutoPlay}
        setAnimateImages={setAnimateImages}
        heroTimerRef={heroTimerRef}
        slideContentRefs={slideContentRefs}
        slidesOuterRef={slidesOuterRef}
      />

      <div className="horizontalLine" id="horizontalLine" />

      <HeroLottie triggerHeroAnimations={triggerHeroAnimations} />
    </GalleryContainer>
  );
};

Gallery.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
  segments: PropTypes.objectOf(PropTypes.number).isRequired,
};

export default Gallery;
