import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import Media from 'components/media';
import gsap from 'gsap';
import TriangleWhite from 'images/triangleWhite.svg';
import { Draggable, InertiaPlugin } from 'gsap/all';
import HeroTimer from 'components/heroTimer';
import { slideDelay, slideDuration } from 'constants/animation';
import Blinds from 'components/blinds';
import PropTypes from 'prop-types';

const HeroSlideshowContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 20;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  // min-height: 100vh;

  overflow: hidden;

  .triangleContainer {
    position: fixed;
    top: 50%;
    opacity: 0;

    width: 20px;
    height: 20px;

    border: none;
    padding: 0;
    background-color: rgba(0, 0, 0, 0);
    cursor: pointer;

    z-index: 1000;

    .cls-1 {
      fill: white;
    }
  }

  .left {
    left: 2rem;
    transform: translateY(-50%) rotate(90deg);
  }

  .right {
    right: 2rem;
    transform: translateY(-50%) rotate(-90deg);
  }

  @media (max-width: 950px) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media (max-width: 700px) {
    .left {
      left: 0.75rem;
    }

    .right {
      right: 0.75rem;
    }
  }
`;

const LeftSlides = styled.div`
  .slidesOuter {
    position: relative;
    opacity: 0;
  }

  .slidesInner {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .heroSlide {
    height: 100vh;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .heroSlide:first-child .slideContent {
    transform: translateY(100%);
  }

  .slideContent {
    position: relative;
    // height: 100vh;
    height: 100%;
    width: calc(100% * 2 / 3);
  }

  .imageContainer,
  .videoContainer {
    height: calc(100% / 2) !important;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    opacity: 0.7;
  }

  @media (max-width: 950px) {
    visibility: hidden;
  }
`;

const RightSlides = styled.div`
  position: relative;

  ul {
    .heroImage {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      height: 100vh;
      opacity: 0.7;
      display: none;
      overflow: hidden;
    }
  }

  @media (max-width: 950px) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`;

const HeroSlideshow = ({
  images,
  imageRefs,
  blindRefs,
  setCurrentImageWithDirection,
  setCurrentImageWithDestination,
  currentImage,
  titleAnimation,
  titleRefs,
  timer,
  setAutoPlay,
  setAnimateImages,
  heroTimerRef,
  slideContentRefs,
  slidesOuterRef,
}) => {
  let slideRefs = [];

  for (let j = 0; j < images.length; j++) {
    slideRefs.push(useRef(null));
  }

  const wrapPartial = (min, max) => {
    var r = max - min;

    return function(value) {
      var v = value - min;

      return ((r + (v % r)) % r) + min;
    };
  };

  const wrap = wrapPartial(-100, (slideRefs.length - 1) * 100);

  const setPosition = () => {
    for (let i = 0; i < slideRefs.length; i++) {
      gsap.set(slideRefs[i], {
        yPercent: i * 100,
      });
    }
  };

  // LEFT SLIDESHOW / DRAGGABLE

  let dragInstance = useRef(null);
  let proxyRef = useRef();

  let transform = useRef(null);

  let animation = useRef(null);
  let slideHeight;
  let wrapHeight;
  let numSlides = slideRefs.length;
  let slideAnimation = gsap.to({}, 0.1, {});

  const animateSlides = direction => {
    timer.current.restart(true);
    slideAnimation.kill();

    const yVal = parseFloat(
      transform.current.y.substring(0, transform.current.y.length - 2)
    );

    const y = snapY(yVal + direction * slideHeight);

    slideAnimation = gsap.to(proxyRef.current, slideDuration, {
      y: y,
      onUpdate: updateProgress,
    });
  };

  let currentLocation = useRef(0);

  const animateImages = (timeline, direction) => {
    if (!gsap.isTweening(slideRefs))
      timeline
        .to(blindRefs, 0.4, {
          scaleX: 1,
          transformOrigin: direction > 0 ? 'left center' : 'right center',
        })
        .set(imageRefs, {
          display: 'none',
        })

        .set(imageRefs[currentImage.current], { display: 'block' })

        .to(blindRefs, 0.4, {
          scaleX: 0,
          transformOrigin: direction > 0 ? 'right center' : 'left center',
        });
  };

  const animateImagesInitial = (timeline, direction) => {
    if (!gsap.isTweening(slideRefs))
      timeline
        .set(blindRefs, {
          scaleX: 1,
          transformOrigin: direction > 0 ? 'left center' : 'right center',
        })
        .set(imageRefs, {
          display: 'none',
        })

        .set(imageRefs[currentImage.current], { display: 'block' })
        .to(blindRefs, 0.8, {
          scaleX: 0,
          transformOrigin: direction > 0 ? 'right center' : 'left center',
        });
  };

  const updateRightSlideshow = destination => {
    let upcomingImage;
    let direction;

    if (destination % numSlides === 0) {
      upcomingImage = 0;
    } else if (destination <= 0) {
      upcomingImage = (destination % numSlides) * -1;
    } else if (destination > 0) {
      upcomingImage = ((destination % numSlides) - numSlides) * -1;
    }

    if (destination - currentLocation.current !== 0) {
      direction =
        (destination - currentLocation.current) /
        Math.abs(destination - currentLocation.current);
    } else {
      direction = 0;
    }

    setCurrentImageWithDestination(upcomingImage);

    const tl = gsap.timeline({
      onComplete: () => {
        currentLocation.current = destination;
      },
    });

    if (direction !== 0) {
      animateImages(tl, direction);
      titleAnimation(currentImage.current, titleRefs, direction);
      heroTimerRef.current.updateTimerSlide(currentImage.current + 1);
    }
  };

  const snapY = y => {
    updateRightSlideshow(Math.round(y / slideHeight));

    return Math.round(y / slideHeight) * slideHeight;
  };

  const updateProgress = () => {
    const yVal = parseFloat(
      transform.current.y.substring(0, transform.current.y.length - 2)
    );

    const yPercentage = yVal / wrapHeight;

    const trans =
      yPercentage < 0 ? Math.ceil(yPercentage * -1) + yPercentage : yPercentage;

    animation.current.progress(trans);
  };

  const resize = () => {
    const yVal = parseFloat(
      transform.current.y.substring(0, transform.current.y.length - 2)
    );

    const norm = yVal / wrapHeight || 0;

    slideHeight = slideRefs[0].getBoundingClientRect().height;
    wrapHeight = slideHeight * numSlides;

    gsap.set(proxyRef.current, {
      y: norm * wrapHeight,
    });

    animateSlides(0);
    slideAnimation.progress(1);
  };

  const autoPlay = () => {
    if (
      dragInstance.current.isPressed ||
      dragInstance.current.isDragging ||
      dragInstance.current.isThrowing
    ) {
      timer.current.restart(true);
    } else {
      handleSlide(1);
    }
  };

  const handleSlide = direction => {
    // Right == 1, Left  == -1

    const tl = gsap.timeline();
    animateSlides(-direction);
    titleAnimation(currentImage.current, titleRefs, -direction);

    heroTimerRef.current.updateTimerSlide(currentImage.current + 1);

    setCurrentImageWithDirection(direction);
    animateImages(tl, direction);
  };

  useEffect(() => {
    proxyRef.current = document.createElement('div');
    gsap.set(proxyRef.current, { y: '+=0' });
    transform.current = proxyRef.current._gsap;

    gsap.registerPlugin(Draggable, InertiaPlugin);

    setAutoPlay(autoPlay);

    setPosition();

    dragInstance.current = Draggable.create(proxyRef.current, {
      trigger: slidesOuterRef,
      type: 'y',
      // edgeResistance: 0.65,
      inertia: true,
      onPress: function() {
        timer.current.restart(true);
        slideAnimation.kill();
        this.update();
      },
      onDrag: updateProgress,
      onThrowUpdate: updateProgress,
      snap: {
        y: snapY,
      },
    });

    animation.current = gsap.to(slideRefs, 0.8, {
      yPercent: '+=' + numSlides * 100,
      ease: 'none',
      paused: true,
      repeat: -1,
      modifiers: {
        yPercent: wrap,
      },
    });

    resize();

    window.addEventListener('resize', resize);

    return () => {
      window.removeEventListener('resize', resize);
    };
  }, []);

  // RIGHT SLIDESHOW

  useEffect(() => {
    setAnimateImages(animateImagesInitial);
    return () => {};
  }, []);

  return (
    <HeroSlideshowContainer className="heroSlideshowContainer">
      <LeftSlides>
        <div
          className="slidesOuter"
          id="slidesOuter"
          ref={div => {
            slidesOuterRef[0] = div;
          }}
        >
          <ul className="slidesInner">
            {images.map((image, i) => {
              return (
                <li
                  key={i}
                  className="heroSlide"
                  ref={li => {
                    slideRefs[i] = li;
                  }}
                >
                  <div
                    className="slideContent"
                    ref={div => {
                      slideContentRefs[i] = div;
                    }}
                  >
                    <Media
                      videoCheck={image[1].file.url.slice(-3)}
                      videoSrcURL={image[1].file.url}
                      fluid={image[1].fluid}
                      alt={image[1].title}
                      title={image[1].title}
                      description={image[1].description}
                    />
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </LeftSlides>

      <RightSlides>
        <ul>
          {images.map((image, i) => {
            return (
              <li
                key={i}
                className="heroImage"
                ref={li => {
                  imageRefs[i] = li;
                }}
              >
                <Media
                  videoCheck={image[0].file.url.slice(-3)}
                  videoSrcURL={image[0].file.url}
                  fluid={image[0].fluid}
                  alt={image[0].title}
                  title={image[0].title}
                  description={image[0].description}
                />
              </li>
            );
          })}
        </ul>

        <Blinds blindRefs={blindRefs} />
      </RightSlides>

      <button
        className="triangleContainer left"
        onClick={() => {
          handleSlide(-1);
        }}
      >
        <TriangleWhite className={'white'} />
      </button>
      <button
        className="triangleContainer right"
        onClick={() => {
          handleSlide(1);
        }}
      >
        <TriangleWhite className={'white'} />
      </button>

      <HeroTimer
        totalSlides={images.length}
        ref={heroTimerRef}
        slideDelay={slideDelay}
      />
    </HeroSlideshowContainer>
  );
};

HeroSlideshow.propTypes = {
  images: PropTypes.array,
  imageRefs: PropTypes.array,
  blindRefs: PropTypes.array,
  setCurrentImageWithDirection: PropTypes.func,
  setCurrentImageWithDestination: PropTypes.func,
  currentImage: PropTypes.object,
  titleAnimation: PropTypes.func,
  titleRefs: PropTypes.array,
  timer: PropTypes.object,
  setAutoPlay: PropTypes.func,
  setAnimateImages: PropTypes.func,
  heroTimerRef: PropTypes.object,
  slideContentRefs: PropTypes.array,
  slidesOuterRef: PropTypes.array,
};

export default HeroSlideshow;
