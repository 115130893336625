import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import gsap from 'gsap';
import lottie from 'lottie-web';
import PropTypes from 'prop-types';
import * as animationData from '../../assets/animation/03JUNEv5/data';

const HeroLottieContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  min-width: 250px;
  max-width: 800px;
  z-index: 1000;
  pointer-events: none;

  @media (max-width: 950px) {
    width: 75%;
  }

  @media (max-width: 550px) {
    width: 90%;
  }
`;

const HeroLottie = ({ triggerHeroAnimations }) => {
  let animRef = useRef(null);

  const getSize = initial => {
    let size;

    if (typeof initial === 'number') {
      size = `${initial}px`;
    } else {
      size = initial || '100%';
    }

    return size;
  };

  const width = '100%';
  const height = '100%';

  const lottieStyles = {
    width: getSize(width),
    height: getSize(height),
    overflow: 'hidden',
    margin: '0 auto',
    outline: 'none',
  };

  useEffect(() => {
    const options = {
      container: animRef,
      renderer: 'svg',
      loop: false,
      autoplay: false,
      animationData: animationData.default,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    };

    let anim = lottie.loadAnimation(options);

    const onComplete = () => {
      triggerHeroAnimations();
    };

    anim.addEventListener('complete', onComplete);

    setTimeout(() => {
      gsap.to('#linesContainer', 3, { scaleY: 1 });
      gsap.to('.horizontalLine', 3, { scaleX: 1 });
      anim.setSpeed(2);
      anim.play();
    }, 500);

    setTimeout(() => {
      anim.pause;
      anim.setDirection(-1);
      anim.setSpeed(4);
      anim.play;
    }, 3500);

    return () => {
      anim.removeEventListener('complete', onComplete);
      anim.destroy();
    };
  }, []);

  return (
    <HeroLottieContainer>
      <div
        ref={div => (animRef = div)}
        style={lottieStyles}
        title={'Steller Bumper'}
        role={'button'}
        aria-label={'animation'}
        tabIndex="0"
      />
    </HeroLottieContainer>
  );
};

HeroLottie.propTypes = {
  triggerHeroAnimations: PropTypes.func,
};

export default HeroLottie;
