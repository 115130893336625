import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const BlindsContainer = styled.ul`
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  z-index: 50;

  li {
    overflow: hidden;
    position: relative;
    width: calc(100% / ${props => props.segments});

    .blind {
      width: 100%;
      height: 100%;
    }
  }
`;

const Blinds = ({ blindRefs }) => {
  return (
    <BlindsContainer segments={blindRefs.length}>
      {blindRefs.map((blind, i) => {
        return (
          <li key={i}>
            <div
              className={'blind'}
              ref={div => {
                blindRefs[i] = div;
              }}
            ></div>
          </li>
        );
      })}
    </BlindsContainer>
  );
};

Blinds.propTypes = {
  blindRefs: PropTypes.array,
};

export default Blinds;
