import img_0 from './images/img_0.png';
import img_1 from './images/img_1.png';
import img_2 from './images/img_2.png';
import img_3 from './images/img_3.png';

export default {
  v: '5.6.5',
  fr: 23.9759979248047,
  ip: 0,
  op: 287.999975072729,
  w: 250,
  h: 250,
  nm: 'ORDANIS 250px',
  ddd: 0,
  assets: [
    { id: 'image_0', w: 467, h: 467, u: '', p: img_0, e: 0 },
    { id: 'image_1', w: 467, h: 467, u: '', p: img_1, e: 0 },
    { id: 'image_2', w: 304, h: 307, u: '', p: img_2, e: 0 },
    { id: 'image_3', w: 304, h: 307, u: '', p: img_3, e: 0 },
    {
      id: 'comp_0',
      layers: [
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: 'Shape A NEW',
          td: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [1648, 1128, 0], ix: 2 },
            a: { a: 0, k: [-400, 48, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: { a: 0, k: [692, 692], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: 'Ellipse Path 1',
                  mn: 'ADBE Vector Shape - Ellipse',
                  hd: false,
                },
                {
                  ty: 'tm',
                  s: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.833], y: [0.833] },
                        o: { x: [0.167], y: [0.167] },
                        t: 20,
                        s: [0],
                      },
                      { t: 90.9999921236748, s: [0] },
                    ],
                    ix: 1,
                  },
                  e: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.833], y: [0.833] },
                        o: { x: [0.167], y: [0.167] },
                        t: 20,
                        s: [0],
                      },
                      { t: 90.9999921236748, s: [100] },
                    ],
                    ix: 2,
                  },
                  o: { a: 0, k: 0, ix: 3 },
                  m: 1,
                  ix: 2,
                  nm: 'Trim Paths 1',
                  mn: 'ADBE Vector Filter - Trim',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 614, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-410, 46], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Ellipse 1',
              np: 4,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'tm',
              s: { a: 0, k: 0, ix: 1 },
              e: { a: 0, k: 100, ix: 2 },
              o: { a: 0, k: 0, ix: 3 },
              m: 1,
              ix: 2,
              nm: 'Trim Paths 1',
              mn: 'ADBE Vector Filter - Trim',
              hd: false,
            },
          ],
          ip: 0,
          op: 287.999975072729,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 2,
          nm: 'Shape A',
          tt: 1,
          refId: 'image_0',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [1645.734, 1115.803, 0], ix: 2 },
            a: { a: 0, k: [233.066, 233.017, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          ef: [
            {
              ty: 20,
              nm: 'Tint',
              np: 6,
              mn: 'ADBE Tint',
              ix: 1,
              en: 1,
              ef: [
                {
                  ty: 2,
                  nm: 'Map Black To',
                  mn: 'ADBE Tint-0001',
                  ix: 1,
                  v: {
                    a: 0,
                    k: [0.933333337307, 0.172549024224, 0.243137255311, 1],
                    ix: 1,
                  },
                },
                {
                  ty: 2,
                  nm: 'Map White To',
                  mn: 'ADBE Tint-0002',
                  ix: 2,
                  v: {
                    a: 0,
                    k: [0.933333337307, 0.172549024224, 0.243137255311, 1],
                    ix: 2,
                  },
                },
                {
                  ty: 0,
                  nm: 'Amount to Tint',
                  mn: 'ADBE Tint-0003',
                  ix: 3,
                  v: { a: 0, k: 100, ix: 3 },
                },
                { ty: 6, nm: '', mn: 'ADBE Tint-0004', ix: 4, v: 0 },
              ],
            },
          ],
          ip: 0,
          op: 287.999975072729,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 5,
          ty: 4,
          nm: 'Shape B NEW ',
          td: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [1664, 936, 0], ix: 2 },
            a: { a: 0, k: [-400, 48, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 2,
                  ty: 'el',
                  s: { a: 0, k: [692, 692], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: 'Ellipse Path 1',
                  mn: 'ADBE Vector Shape - Ellipse',
                  hd: false,
                },
                {
                  ty: 'tm',
                  s: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.833], y: [0.833] },
                        o: { x: [0.167], y: [0.167] },
                        t: 20,
                        s: [0],
                      },
                      { t: 90.9999921236748, s: [0] },
                    ],
                    ix: 1,
                  },
                  e: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.833], y: [0.833] },
                        o: { x: [0.167], y: [0.167] },
                        t: 20,
                        s: [0],
                      },
                      { t: 90.9999921236748, s: [100] },
                    ],
                    ix: 2,
                  },
                  o: { a: 0, k: 180, ix: 3 },
                  m: 1,
                  ix: 2,
                  nm: 'Trim Paths 1',
                  mn: 'ADBE Vector Filter - Trim',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 655, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-410, 46], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Ellipse 1',
              np: 4,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'tm',
              s: { a: 0, k: 0, ix: 1 },
              e: { a: 0, k: 100, ix: 2 },
              o: { a: 0, k: 0, ix: 3 },
              m: 1,
              ix: 2,
              nm: 'Trim Paths 1',
              mn: 'ADBE Vector Filter - Trim',
              hd: false,
            },
          ],
          ip: 0,
          op: 287.999975072729,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 6,
          ty: 2,
          nm: 'Shape B',
          tt: 1,
          refId: 'image_1',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [1645.735, 956.74, 0], ix: 2 },
            a: { a: 0, k: [233.066, 233.02, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          ef: [
            {
              ty: 20,
              nm: 'Tint',
              np: 6,
              mn: 'ADBE Tint',
              ix: 1,
              en: 1,
              ef: [
                {
                  ty: 2,
                  nm: 'Map Black To',
                  mn: 'ADBE Tint-0001',
                  ix: 1,
                  v: {
                    a: 0,
                    k: [0.933333337307, 0.172549024224, 0.243137255311, 1],
                    ix: 1,
                  },
                },
                {
                  ty: 2,
                  nm: 'Map White To',
                  mn: 'ADBE Tint-0002',
                  ix: 2,
                  v: {
                    a: 0,
                    k: [0.933333337307, 0.172549024224, 0.243137255311, 1],
                    ix: 2,
                  },
                },
                {
                  ty: 0,
                  nm: 'Amount to Tint',
                  mn: 'ADBE Tint-0003',
                  ix: 3,
                  v: { a: 0, k: 100, ix: 3 },
                },
                { ty: 6, nm: '', mn: 'ADBE Tint-0004', ix: 4, v: 0 },
              ],
            },
          ],
          ip: 0,
          op: 287.999975072729,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 7,
          ty: 4,
          nm: 'Shape C NEW',
          td: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [1664, 936, 0], ix: 2 },
            a: { a: 0, k: [-400, 48, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 2,
                  ty: 'el',
                  s: { a: 0, k: [692, 692], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: 'Ellipse Path 1',
                  mn: 'ADBE Vector Shape - Ellipse',
                  hd: false,
                },
                {
                  ty: 'tm',
                  s: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.833], y: [0.833] },
                        o: { x: [0.167], y: [0.167] },
                        t: 58,
                        s: [0],
                      },
                      { t: 90.9999921236748, s: [0] },
                    ],
                    ix: 1,
                  },
                  e: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.833], y: [0.833] },
                        o: { x: [0.167], y: [0.167] },
                        t: 58,
                        s: [0],
                      },
                      { t: 90.9999921236748, s: [100] },
                    ],
                    ix: 2,
                  },
                  o: { a: 0, k: 180, ix: 3 },
                  m: 1,
                  ix: 2,
                  nm: 'Trim Paths 1',
                  mn: 'ADBE Vector Filter - Trim',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 655, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-410, 46], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Ellipse 1',
              np: 4,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'tm',
              s: { a: 0, k: 0, ix: 1 },
              e: { a: 0, k: 100, ix: 2 },
              o: { a: 0, k: 0, ix: 3 },
              m: 1,
              ix: 2,
              nm: 'Trim Paths 1',
              mn: 'ADBE Vector Filter - Trim',
              hd: false,
            },
          ],
          ip: 0,
          op: 287.999975072729,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 8,
          ty: 2,
          nm: 'Shape C',
          tt: 1,
          refId: 'image_2',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [1643.794, 956.696, 0], ix: 2 },
            a: { a: 0, k: [151.605, 153.483, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          ef: [
            {
              ty: 20,
              nm: 'Tint',
              np: 6,
              mn: 'ADBE Tint',
              ix: 1,
              en: 1,
              ef: [
                {
                  ty: 2,
                  nm: 'Map Black To',
                  mn: 'ADBE Tint-0001',
                  ix: 1,
                  v: {
                    a: 0,
                    k: [0.933333337307, 0.172549024224, 0.243137255311, 1],
                    ix: 1,
                  },
                },
                {
                  ty: 2,
                  nm: 'Map White To',
                  mn: 'ADBE Tint-0002',
                  ix: 2,
                  v: {
                    a: 0,
                    k: [0.933333337307, 0.172549024224, 0.243137255311, 1],
                    ix: 2,
                  },
                },
                {
                  ty: 0,
                  nm: 'Amount to Tint',
                  mn: 'ADBE Tint-0003',
                  ix: 3,
                  v: { a: 0, k: 100, ix: 3 },
                },
                { ty: 6, nm: '', mn: 'ADBE Tint-0004', ix: 4, v: 0 },
              ],
            },
          ],
          ip: 0,
          op: 287.999975072729,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 9,
          ty: 4,
          nm: 'Shape D NEW ',
          td: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 180, ix: 10 },
            p: { a: 0, k: [1620, 1144, 0], ix: 2 },
            a: { a: 0, k: [-400, 48, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 2,
                  ty: 'el',
                  s: { a: 0, k: [692, 692], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: 'Ellipse Path 1',
                  mn: 'ADBE Vector Shape - Ellipse',
                  hd: false,
                },
                {
                  ty: 'tm',
                  s: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.833], y: [0.833] },
                        o: { x: [0.167], y: [0.167] },
                        t: 58,
                        s: [0],
                      },
                      { t: 90.9999921236748, s: [0] },
                    ],
                    ix: 1,
                  },
                  e: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.833], y: [0.833] },
                        o: { x: [0.167], y: [0.167] },
                        t: 58,
                        s: [0],
                      },
                      { t: 90.9999921236748, s: [100] },
                    ],
                    ix: 2,
                  },
                  o: { a: 0, k: 180, ix: 3 },
                  m: 1,
                  ix: 2,
                  nm: 'Trim Paths 1',
                  mn: 'ADBE Vector Filter - Trim',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 655, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-410, 46], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Ellipse 1',
              np: 4,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'tm',
              s: { a: 0, k: 0, ix: 1 },
              e: { a: 0, k: 100, ix: 2 },
              o: { a: 0, k: 0, ix: 3 },
              m: 1,
              ix: 2,
              nm: 'Trim Paths 1',
              mn: 'ADBE Vector Filter - Trim',
              hd: false,
            },
          ],
          ip: 0,
          op: 287.999975072729,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 10,
          ty: 2,
          nm: 'Shape D',
          tt: 1,
          refId: 'image_3',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [1647.688, 1115.835, 0], ix: 2 },
            a: { a: 0, k: [151.609, 153.495, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          ef: [
            {
              ty: 20,
              nm: 'Tint',
              np: 6,
              mn: 'ADBE Tint',
              ix: 1,
              en: 1,
              ef: [
                {
                  ty: 2,
                  nm: 'Map Black To',
                  mn: 'ADBE Tint-0001',
                  ix: 1,
                  v: {
                    a: 0,
                    k: [0.933333337307, 0.172549024224, 0.243137255311, 1],
                    ix: 1,
                  },
                },
                {
                  ty: 2,
                  nm: 'Map White To',
                  mn: 'ADBE Tint-0002',
                  ix: 2,
                  v: {
                    a: 0,
                    k: [0.933333337307, 0.172549024224, 0.243137255311, 1],
                    ix: 2,
                  },
                },
                {
                  ty: 0,
                  nm: 'Amount to Tint',
                  mn: 'ADBE Tint-0003',
                  ix: 3,
                  v: { a: 0, k: 100, ix: 3 },
                },
                { ty: 6, nm: '', mn: 'ADBE Tint-0004', ix: 4, v: 0 },
              ],
            },
          ],
          ip: 0,
          op: 287.999975072729,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 12,
          ty: 5,
          nm: 'Stellar 2',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 80,
                  s: [1910.332, 1014, 0],
                  to: [0, -26.667, 0],
                  ti: [0, 26.667, 0],
                },
                { t: 99.9999913446975, s: [1910.332, 854, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          ef: [
            {
              ty: 20,
              nm: 'Tint',
              np: 6,
              mn: 'ADBE Tint',
              ix: 1,
              en: 1,
              ef: [
                {
                  ty: 2,
                  nm: 'Map Black To',
                  mn: 'ADBE Tint-0001',
                  ix: 1,
                  v: {
                    a: 0,
                    k: [0.933333337307, 0.172549024224, 0.243137255311, 1],
                    ix: 1,
                  },
                },
                {
                  ty: 2,
                  nm: 'Map White To',
                  mn: 'ADBE Tint-0002',
                  ix: 2,
                  v: {
                    a: 0,
                    k: [0.933333337307, 0.172549024224, 0.243137255311, 1],
                    ix: 2,
                  },
                },
                {
                  ty: 0,
                  nm: 'Amount to Tint',
                  mn: 'ADBE Tint-0003',
                  ix: 3,
                  v: { a: 0, k: 100, ix: 3 },
                },
                { ty: 6, nm: '', mn: 'ADBE Tint-0004', ix: 4, v: 0 },
              ],
            },
          ],
          t: {
            d: {
              k: [
                {
                  s: {
                    s: 182,
                    f: 'DINPro-Bold',
                    t: 'Stellar',
                    j: 0,
                    tr: 0,
                    lh: 218.4,
                    ls: 0,
                    fc: [0.988, 0.043, 0.114],
                  },
                  t: 0,
                },
              ],
            },
            p: {},
            m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
            a: [],
          },
          ip: 79.999993075758,
          op: 240.999979140721,
          st: 15.9999986151516,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 14,
          ty: 5,
          nm: ' Citizens',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 104,
                  s: [1906, 957, 0],
                  to: [0, -26.667, 0],
                  ti: [0, 26.667, 0],
                },
                { t: 124.999989180872, s: [1906, 797, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          ef: [
            {
              ty: 20,
              nm: 'Tint',
              np: 6,
              mn: 'ADBE Tint',
              ix: 1,
              en: 1,
              ef: [
                {
                  ty: 2,
                  nm: 'Map Black To',
                  mn: 'ADBE Tint-0001',
                  ix: 1,
                  v: {
                    a: 0,
                    k: [0.933333337307, 0.172549024224, 0.243137255311, 1],
                    ix: 1,
                  },
                },
                {
                  ty: 2,
                  nm: 'Map White To',
                  mn: 'ADBE Tint-0002',
                  ix: 2,
                  v: {
                    a: 0,
                    k: [0.933333337307, 0.172549024224, 0.243137255311, 1],
                    ix: 2,
                  },
                },
                {
                  ty: 0,
                  nm: 'Amount to Tint',
                  mn: 'ADBE Tint-0003',
                  ix: 3,
                  v: { a: 0, k: 100, ix: 3 },
                },
                { ty: 6, nm: '', mn: 'ADBE Tint-0004', ix: 4, v: 0 },
              ],
            },
          ],
          t: {
            d: {
              k: [
                {
                  s: {
                    s: 182,
                    f: 'DINPro-Bold',
                    t: '\rCitizens',
                    j: 0,
                    tr: 0,
                    lh: 218.4,
                    ls: 0,
                    fc: [0.988, 0.043, 0.114],
                  },
                  t: 0,
                },
              ],
            },
            p: {},
            m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
            a: [],
          },
          ip: 102.999991085038,
          op: 240.999979140721,
          st: 15.9999986151516,
          bm: 0,
        },
      ],
    },
  ],
  fonts: {
    list: [
      {
        fName: 'DINPro-Bold',
        fFamily: 'DINPro',
        fStyle: 'Bold',
        ascent: 71.1990356445313,
      },
    ],
  },
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 0,
      nm: 'Red on Black 2',
      refId: 'comp_0',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [136, 133, 0], ix: 2 },
        a: { a: 0, k: [2048, 1080, 0], ix: 1 },
        s: { a: 0, k: [17.222, 17.222, 100], ix: 6 },
      },
      ao: 0,
      w: 4096,
      h: 2160,
      ip: 0,
      op: 287.999975072729,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
  chars: [
    { ch: '\r', size: 182, style: 'Bold', w: 0, fFamily: 'DINPro' },
    {
      ch: 'C',
      size: 182,
      style: 'Bold',
      w: 58.8,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-2.417, 14.603],
                      [0, 0],
                      [6.747, 0],
                      [2.115, 2.518],
                      [0, 13.898],
                      [-2.921, 3.122],
                      [-3.827, 0],
                      [-1.511, -6.143],
                      [0, 0],
                      [13.797, 0],
                      [5.237, -5.237],
                      [0.806, -4.129],
                      [0, -7.855],
                      [-0.806, -4.028],
                      [-3.122, -3.122],
                      [-7.654, 0],
                    ],
                    o: [
                      [0, 0],
                      [-1.511, 6.143],
                      [-3.827, 0],
                      [-2.921, -3.122],
                      [0, -13.898],
                      [2.115, -2.518],
                      [6.747, 0],
                      [0, 0],
                      [-2.417, -14.603],
                      [-7.654, 0],
                      [-3.122, 3.122],
                      [-0.806, 4.028],
                      [0, 7.855],
                      [0.806, 4.028],
                      [5.237, 5.237],
                      [13.696, 0],
                    ],
                    v: [
                      [58.713, -22.055],
                      [44.513, -22.055],
                      [32.428, -11.884],
                      [23.465, -15.61],
                      [19.94, -35.852],
                      [23.465, -56.094],
                      [32.428, -59.821],
                      [44.513, -49.649],
                      [58.713, -49.649],
                      [32.327, -72.308],
                      [12.991, -64.453],
                      [7.15, -53.677],
                      [5.942, -35.852],
                      [7.15, -18.027],
                      [12.991, -7.251],
                      [32.327, 0.604],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'C',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: 'C',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'DINPro',
    },
    {
      ch: 'i',
      size: 182,
      style: 'Bold',
      w: 27.5,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [20.444, 0],
                      [20.444, -52.469],
                      [7.352, -52.469],
                      [7.352, 0],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'i',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ind: 1,
                ty: 'sh',
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [20.544, -61.432],
                      [20.544, -72.107],
                      [7.15, -72.107],
                      [7.15, -61.432],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'i',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: 'i',
            np: 5,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'DINPro',
    },
    {
      ch: 't',
      size: 182,
      style: 'Bold',
      w: 35.2,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 3.021],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-10.675, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [-3.122, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 7.452],
                      [0, 0],
                    ],
                    v: [
                      [30.917, 0],
                      [30.917, -11.078],
                      [26.184, -11.078],
                      [21.652, -15.811],
                      [21.652, -40.988],
                      [30.917, -40.988],
                      [30.917, -50.958],
                      [21.652, -50.958],
                      [21.652, -66.467],
                      [8.56, -66.467],
                      [8.56, -50.958],
                      [3.021, -50.958],
                      [3.021, -40.988],
                      [8.56, -40.988],
                      [8.56, -15.005],
                      [23.767, 0],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 't',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: 't',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'DINPro',
    },
    {
      ch: 'z',
      size: 182,
      style: 'Bold',
      w: 47.8,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [43.707, 0],
                      [43.707, -11.783],
                      [20.142, -11.783],
                      [43.707, -42.499],
                      [43.707, -52.469],
                      [5.035, -52.469],
                      [5.035, -40.686],
                      [26.99, -40.686],
                      [3.726, -9.97],
                      [3.726, 0],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'z',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: 'z',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'DINPro',
    },
    {
      ch: 'e',
      size: 182,
      style: 'Bold',
      w: 54.8,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [14.603, 0],
                      [0, -17.12],
                      [-12.387, 0],
                      [-5.338, 5.338],
                      [0, 0],
                      [5.74, 0],
                      [0, 6.747],
                    ],
                    o: [
                      [0, 0],
                      [0, -14.401],
                      [-13.596, 0],
                      [0, 21.249],
                      [9.467, 0],
                      [0, 0],
                      [-3.323, 3.323],
                      [-7.352, 0],
                      [0, 0],
                    ],
                    v: [
                      [50.455, -22.156],
                      [50.455, -27.997],
                      [27.594, -53.073],
                      [4.733, -26.285],
                      [29.004, 0.604],
                      [48.944, -7.654],
                      [40.988, -15.408],
                      [29.105, -10.474],
                      [17.624, -22.156],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'e',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ind: 1,
                ty: 'sh',
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-0.101, -2.316],
                      [0, 0],
                      [-0.806, 1.913],
                      [-4.431, 0],
                      [-1.41, -3.122],
                    ],
                    o: [
                      [0, 0],
                      [0.101, -2.316],
                      [1.41, -3.122],
                      [4.431, 0],
                      [0.806, 1.913],
                    ],
                    v: [
                      [37.564, -31.119],
                      [17.624, -31.119],
                      [18.832, -36.758],
                      [27.594, -42.197],
                      [36.356, -36.758],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'e',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: 'e',
            np: 5,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'DINPro',
    },
    {
      ch: 'n',
      size: 182,
      style: 'Bold',
      w: 57.3,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [4.23, 4.23],
                      [5.237, 0],
                      [3.424, -3.625],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-4.129, 0],
                      [0, -7.15],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, -5.74],
                      [-2.921, -2.921],
                      [-4.834, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, -7.15],
                      [4.129, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [50.958, 0],
                      [50.958, -33.536],
                      [45.621, -48.441],
                      [33.234, -53.073],
                      [20.142, -47.635],
                      [20.142, -52.469],
                      [7.352, -52.469],
                      [7.352, 0],
                      [20.444, 0],
                      [20.444, -31.723],
                      [29.205, -41.29],
                      [37.866, -31.723],
                      [37.866, 0],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'n',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: 'n',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'DINPro',
    },
    {
      ch: 's',
      size: 182,
      style: 'Bold',
      w: 49.5,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 11.581],
                      [10.373, 1.007],
                      [0, 0],
                      [0, 1.913],
                      [-5.237, 0],
                      [-3.021, -2.719],
                      [0, 0],
                      [8.157, 0],
                      [0, -10.876],
                      [-9.467, -0.906],
                      [0, 0],
                      [0, -2.618],
                      [4.532, 0],
                      [4.33, 4.33],
                      [0, 0],
                      [-8.258, 0],
                    ],
                    o: [
                      [0, -8.862],
                      [0, 0],
                      [-4.633, -0.403],
                      [0, -2.618],
                      [4.532, 0],
                      [0, 0],
                      [-5.035, -4.733],
                      [-10.675, 0],
                      [0, 9.668],
                      [0, 0],
                      [4.129, 0.403],
                      [0, 3.928],
                      [-4.028, 0],
                      [0, 0],
                      [6.647, 6.647],
                      [12.387, 0],
                    ],
                    v: [
                      [46.829, -16.516],
                      [31.219, -31.924],
                      [22.961, -32.73],
                      [17.523, -37.262],
                      [24.976, -42.297],
                      [37.161, -38.773],
                      [45.218, -46.93],
                      [25.177, -53.073],
                      [4.935, -36.356],
                      [20.242, -21.652],
                      [28.5, -20.847],
                      [34.039, -16.013],
                      [24.774, -10.574],
                      [10.776, -15.509],
                      [2.216, -6.949],
                      [24.573, 0.604],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 's',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: 's',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'DINPro',
    },
    {
      ch: 'S',
      size: 182,
      style: 'Bold',
      w: 58.5,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 13.898],
                      [3.827, 3.625],
                      [6.546, 0.906],
                      [0, 0],
                      [1.41, 1.309],
                      [0, 1.813],
                      [-7.452, 0],
                      [-4.532, -4.33],
                      [0, 0],
                      [9.869, 0],
                      [0, -12.79],
                      [-3.424, -3.424],
                      [-6.143, -0.906],
                      [0, 0],
                      [-1.41, -1.309],
                      [0, -2.518],
                      [8.258, 0],
                      [4.532, 4.532],
                      [0, 0],
                      [-10.876, 0],
                    ],
                    o: [
                      [0, -6.345],
                      [-3.021, -2.82],
                      [0, 0],
                      [-2.921, -0.403],
                      [-1.511, -1.41],
                      [0, -4.935],
                      [4.733, 0],
                      [0, 0],
                      [-6.143, -5.942],
                      [-15.509, 0],
                      [0, 6.042],
                      [3.223, 3.122],
                      [0, 0],
                      [3.323, 0.504],
                      [1.511, 1.41],
                      [0, 5.74],
                      [-6.647, 0],
                      [0, 0],
                      [7.05, 7.15],
                      [15.005, 0],
                    ],
                    v: [
                      [54.684, -21.149],
                      [49.146, -36.356],
                      [35.449, -41.895],
                      [26.99, -43.103],
                      [20.343, -45.923],
                      [18.228, -51.059],
                      [29.306, -60.123],
                      [44.009, -55.188],
                      [52.872, -63.95],
                      [29.709, -72.308],
                      [4.733, -50.555],
                      [9.869, -36.456],
                      [23.767, -30.515],
                      [32.428, -29.306],
                      [38.773, -26.788],
                      [40.988, -20.746],
                      [28.299, -11.884],
                      [10.977, -17.926],
                      [1.913, -8.862],
                      [28.098, 0.604],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'S',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: 'S',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'DINPro',
    },
    {
      ch: 'l',
      size: 182,
      style: 'Bold',
      w: 32.5,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 3.122],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-10.776, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [-3.323, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 7.452],
                      [0, 0],
                    ],
                    v: [
                      [29.608, 0],
                      [29.608, -11.078],
                      [24.573, -11.078],
                      [19.94, -15.811],
                      [19.94, -71.704],
                      [6.848, -71.704],
                      [6.848, -15.005],
                      [22.156, 0],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'l',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: 'l',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'DINPro',
    },
    {
      ch: 'a',
      size: 182,
      style: 'Bold',
      w: 53.4,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [14.703, 0],
                      [5.136, -5.64],
                      [0, 0],
                      [-5.035, 0],
                      [0, -5.74],
                      [0, 0],
                      [0, 0],
                      [0, -8.359],
                      [-2.82, -2.921],
                      [-5.942, 0],
                      [-3.525, 3.525],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, -12.286],
                      [-8.762, 0],
                      [0, 0],
                      [2.719, -3.122],
                      [6.949, 0],
                      [0, 0],
                      [0, 0],
                      [-12.186, 0],
                      [0, 4.633],
                      [3.122, 3.122],
                      [6.042, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [47.031, 0],
                      [47.031, -34.543],
                      [24.673, -53.073],
                      [5.74, -45.822],
                      [14.099, -37.665],
                      [24.17, -41.995],
                      [33.939, -33.737],
                      [33.939, -31.119],
                      [21.552, -31.119],
                      [3.625, -15.912],
                      [7.956, -4.129],
                      [21.35, 0.504],
                      [34.241, -4.532],
                      [34.241, 0],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'a',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ind: 1,
                ty: 'sh',
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [1.41, -1.309],
                      [3.625, 0],
                      [0, 3.625],
                      [-4.733, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 3.223],
                      [-2.316, 2.216],
                      [-5.136, 0],
                      [0, -3.726],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [33.939, -19.336],
                      [32.025, -12.689],
                      [23.767, -10.172],
                      [16.214, -16.214],
                      [23.566, -22.156],
                      [33.939, -22.156],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'a',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: 'a',
            np: 5,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'DINPro',
    },
    {
      ch: 'r',
      size: 182,
      style: 'Bold',
      w: 45.5,
      data: {
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [5.136, 0],
                      [2.518, -3.021],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-4.028, 0],
                      [-2.014, -2.014],
                    ],
                    o: [
                      [-3.625, -3.625],
                      [-5.64, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, -6.647],
                      [3.223, 0],
                      [0, 0],
                    ],
                    v: [
                      [45.721, -48.138],
                      [33.334, -53.073],
                      [20.142, -47.433],
                      [20.142, -52.469],
                      [7.352, -52.469],
                      [7.352, 0],
                      [20.444, 0],
                      [20.444, -31.622],
                      [28.903, -41.29],
                      [35.852, -38.168],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'r',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
            ],
            nm: 'r',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
      },
      fFamily: 'DINPro',
    },
  ],
};
