import React from 'react';
import styled from 'styled-components';
import Media from 'components/media';
import SlidingTitle from 'components/slidingTitle';
import PropTypes from 'prop-types';

const ContactContainer = styled.div`
  width: calc(100% * 2 / 3);
  margin: 0 auto;
  padding: 4vw 0 14vw 0;
  display: flex;
  min-height: 64vh;

  .contactLeft,
  .contactRight {
    width: 50%;
  }

  .email {
    margin-bottom: 5vw;
  }

  .emailLink {
    padding: 25px 0;
    white-space: nowrap;
  }

  .contactRight {
    display: flex;
    justify-content: flex-end;
  }

  .address {
    width: 50%;
    transform: translateY(200px);
  }

  .addressDetails {
    p {
      padding: 25px 0;
      font-size: 1rem;
      white-space: nowrap;
    }
  }

  @media (max-width: 950px) {
    width: 100%;

    .contactLeft {
      width: calc(100% * 2 / 3);
      display: flex;
      justify-content: center;
    }

    .contactLeftSub {
      width: 75%;
    }

    .contactRight {
      width: calc(100% * 1 / 3);
      justify-content: flex-start;
    }

    .address {
      width: 75%;
    }
  }

  @media (max-width: 500px) {
    .contactLeft {
      justify-content: flex-end;
    }

    .contactLeftSub {
      width: calc(100% - 1.5rem - 16px);
    }

    .address {
      width: 100%;
      transform: translate(calc(-100% / 3), 200px);
    }
  }

  @media (max-width: 450px) {
    padding-top: 30vw;
    padding-bottom: 40vw;
  }
`;

const Contact = ({ data }) => {
  return (
    <ContactContainer>
      <div className="contactLeft" data-scroll data-scroll-speed="2">
        <div className="contactLeftSub">
          <div className="email">
            <div className="emailTitle">
              <SlidingTitle title={data.title1} />
            </div>
            <div className="emailLink">{data.email}</div>
          </div>
          <div className="contactImage">
            <Media
              videoCheck={data.media.file.url.slice(-3)}
              videoSrcURL={data.media.file.url}
              fluid={data.media.fluid}
              alt={data.media.title}
              title={data.media.title}
              description={data.media.description}
            />
          </div>
        </div>
      </div>
      <div className="contactRight" data-scroll data-scroll-speed="6">
        <div className="address">
          <div className="locationTitle">
            <SlidingTitle title={data.title2} />
          </div>
          <div
            className="addressDetails"
            dangerouslySetInnerHTML={{
              __html: data.address.childMarkdownRemark.html,
            }}
          />
        </div>
      </div>
    </ContactContainer>
  );
};

Contact.propTypes = {
  data: PropTypes.object,
};

export default Contact;
