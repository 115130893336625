import React from 'react';
import styled from 'styled-components';
import ScrollingTitle from 'components/scrollingTitle';
import Media from 'components/media';
import StellarLogo from 'images/stellar.svg';
import PropTypes from 'prop-types';
import SlidingButton from 'components/slidingButton';
import { Link } from 'gatsby';

const IntroContainer = styled.div`
  width: calc(100% * 4 / 6);
  margin: 0 auto;
  padding: 14vw 0;
  position: relative;

  .titleContainer {
    width: 25%;
    margin-left: 25%
    margin-bottom: 3.5vw;
  }

  @media (max-width: 800px) {
    width: 100%;

    .titleContainer {
      margin-left: calc(100% / 3);
      width: calc(100% / 6);
    }
  }

  @media (max-width: 550px) {

    padding: 24vw 0;

    .titleContainer {
      margin-left: 0;
      width: calc(100% / 3);
    }
  }
`;

const MainText = styled.div`
  display: flex;

  .introLeft {
    width: 25%;
  }

  .stellarLogo {
    width: 25%;
    position: absolute;
    left: calc(-25% / 2);
    top: 30%;
    transform: translate(-50%, -50%);
    z-index: -1;
  }

  h3 {
    margin: 4vw 0;
    position: relative;
    z-index: 10;
    white-space: nowrap;
  }

  .introRight {
    width: 75%;
  }

  .buttonContainer {
    width: 105px;
    margin-left: 10px;
  }

  @media (max-width: 1250px) {
    .introContent {
      width: 200%;
      position: relative;
      z-index: 10;
    }
  }

  @media (max-width: 800px) {
    .introLeft {
      width: calc(100% / 3);
      transform: translateX(50%);
      position: relative;
      z-index: 10;

      h3 p {
        font-size: 1.5rem;
      }
    }

    .introContent {
      width: 100%;
    }

    .introRight {
      width: calc(100% * 2 / 3);
    }

    .stellarLogo {
      width: 50%;
      left: -25%;
    }
  }

  @media (max-width: 550px) {
    .introLeft {
      p,
      h3 p {
        padding-left: 0;
      }
    }

    .introContent {
      width: 150%;
    }

    .introRight {
      display: none;
    }

    .stellarLogo {
      width: 100%;
      left: 100%;
    }

    .buttonContainer {
      margin-left: 0px;
    }
  }
`;

const SlidingText = styled.ul`
  position: absolute;
  right: 0;
  top: 0;
  margin: 0;
  transform: translate(6.4vw, 0) rotate(-90deg);
  transform-origin: bottom right;
  // font-family: serif;
  font-size: 4.8vw;
  opacity: 0.9;
  line-height: 1.3;

  span {
    display: inline-block;
  }

  li:nth-of-type(1) span {
    transform: translateX(-30%);
  }

  li:nth-of-type(3) span {
    transform: translateX(20%);
  }

  @media (max-width: 800px) {
    transform: translate(-1.4rem, 0) rotate(-90deg);
    font-size: 6vw;
  }

  @media (max-width: 550px) {
    display: none;
  }
`;

const Introduction = ({ data }) => {
  return (
    <IntroContainer id="scroll-direction">
      <ScrollingTitle title={'What We Do'} width={4} />
      <MainText>
        <div className="introLeft">
          <h3
            dangerouslySetInnerHTML={{
              __html: data.headline.childMarkdownRemark.html,
            }}
          />

          <div
            className="introContent"
            dangerouslySetInnerHTML={{
              __html: data.paragraph.childMarkdownRemark.html,
            }}
          />
          <div className="buttonContainer">
            <Link to="about">
              <SlidingButton title={'Read More'} />
            </Link>
          </div>
          <StellarLogo
            className="stellarLogo"
            data-scroll
            data-scroll-direction="vertical"
            data-scroll-speed="-2"
            data-scroll-target="#scroll-direction"
          />
        </div>
        <div className="introRight">
          <Media
            videoCheck={data.media.file.url.slice(-3)}
            videoSrcURL={data.media.file.url}
            fluid={data.media.fluid}
            alt={data.media.title}
            title={data.media.title}
            description={data.media.description}
          />
        </div>
      </MainText>
      <SlidingText id="slidingText">
        <li
          data-scroll
          data-scroll-direction="horizontal"
          data-scroll-speed="-4"
          data-scroll-target="#scroll-direction"
        >
          <span>{data.expertise1}</span>
        </li>
        <li
          data-scroll
          data-scroll-direction="horizontal"
          data-scroll-speed="-6"
          data-scroll-target="#scroll-direction"
        >
          <span>{data.expertise2}</span>
        </li>
        <li
          data-scroll
          data-scroll-direction="horizontal"
          data-scroll-speed="-8"
          data-scroll-target="#scroll-direction"
        >
          <span>{data.expertise3}</span>
        </li>
      </SlidingText>
    </IntroContainer>
  );
};

Introduction.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Introduction;
