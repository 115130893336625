import React from 'react';
import styled from 'styled-components';
import TriangleWhite from 'images/triangleWhite.svg';
import PropTypes from 'prop-types';

const SlidingTitleContainer = styled.div`
  overflow: hidden;
  position: relative;

  .titleTop {
    height: 42px;
    position: relative;
    z-index: 10;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .title {
    transition: all 0.4s;
    font-size: 0.6rem;
    font-weight: 700;
  }

  .triangleContainer {
    width: 15px;
    position: relative;
    transition: all 0.4s;

    .white {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }

    .cls-1 {
      transition: all 0.4s;
    }
  }

  .titleBottom {
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    background-color: white;
    position: absolute;
    transform: scaleY(0.025);
    transform-origin: bottom center;
    transition: transform 0.4s;
  }

  &:hover {
    .titleBottom {
      transform: scaleY(1);
    }

    .title {
      color: black;
      transform: translateX(10px);
    }

    .cls-1 {
      fill: #1c1c20;
    }

    .triangleContainer {
      transform: translateX(-10px);
    }
  }
`;

const SlidingTitle = ({ title }) => {
  return (
    <SlidingTitleContainer>
      <div className="titleTop">
        <div className="title">{title}</div>
        <div className="triangleContainer">
          <TriangleWhite className={'white'} />
        </div>
      </div>
      <div className="titleBottom"></div>
    </SlidingTitleContainer>
  );
};

SlidingTitle.propTypes = {
  title: PropTypes.string,
};

export default SlidingTitle;
